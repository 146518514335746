import { B1 } from "components/type/Typography";
import {
  LivingSituation,
  MeUserFragment,
  PurchaseLoanAmountType,
  RelationshipStatus,
} from "graphql/generated";
import { formatCurrency } from "lib/format-currency";
import { getInt } from "lib/get-number";
import { get, last } from "lodash";
import { FormEventHandler, ReactNode } from "react";
import {
  Control,
  DeepPartial,
  RegisterOptions,
  UnpackNestedValue,
  useForm,
  UseFormMethods,
} from "react-hook-form";
import { Button } from "../button/button";
import { CheckboxWithLabel } from "./Checkbox";
import {
  CurrencyInput,
  CurrencyInputProps,
  CurrencyInputWithoutLabel,
} from "./CurrencyInput";
import { billFrequencyOptions } from "./formData/billFrequencyOptions";
import {
  goalKeyOptions,
  goalTermOptions,
  MortageLoanTypes,
} from "./formData/goalOptions";
import { hecsHelpOptions } from "./formData/hecsHelpOptions";
import { incomeTypeOptions } from "./formData/incomeTypeOptions";
import {
  KeepVsSellOptions,
  UseEquityOption,
} from "./formData/keepVsSellOptions";
import { livingSituationOptions } from "./formData/LivingSituation";
import { personalityTypeOptions } from "./formData/personalityTypeOptions";
import {
  FbhOptions,
  PlannedPurchaseOption,
  PropertyTypeOptions,
  PurchaseLoanTypeOptions,
  RepaymentFrequencyOption,
  StateOptions,
  getLmiWaiverOptionsWithChecks,
} from "./formData/plannedPurchaseOptions";
import { relationshipStatusOptions } from "./formData/RelationshipStatus";
import { Input, InputProps as InputProperties } from "./Input";
import { MobileNumberInput } from "./MobileNumberInput";
import {
  Select,
  SelectProps as SelectProperties,
  WithoutLabelSelect,
} from "./select";
import {
  SliderInput,
  SliderInputProps,
  SliderInputWithoutLabels,
} from "./slider-input";
import { SteppedNumberInput } from "./stepped-number-input";
import { ToggleWithLabel } from "./Toggle";
import { FORM_CONSTANT } from "./constant";

const required = "This field is required";
const minLength = (value) => ({
  value: value,
  message: `This field must be at least ${value} characters long`,
});
const maxLength = (value) => ({
  value: value,
  message: `This field must be at most ${value} characters long`,
});

const pattern = (value) => ({
  value: value,
  message: `This field must contain at least one of each Uppercase, Lowercase, Number, and Special Character.`,
});

export type HandleSubmit = FormEventHandler<HTMLFormElement>;
export type FormKey<T> = keyof T | `${string}.${string & keyof T}`;
export type GetInput<T> = (inputId: FormKey<T>, extra?: any) => ReactNode;
export type SubmitButton = (label: string) => ReactNode;
export type UseStandardFormReturnType<T = unknown> = {
  form: UseFormMethods<T>;
  getInput: GetInput<T>;
  submitButton: SubmitButton;
};

export function useStandardForm<FormDataType>(
  user: MeUserFragment,
  defaultValues: FormDataType,
  formLabel: FormType
): UseStandardFormReturnType<FormDataType> {
  const form = useForm<FormDataType>({
    defaultValues: defaultValues as UnpackNestedValue<
      DeepPartial<FormDataType>
    >,
    mode: "onChange",
  });

  const getInput = createGetInput<FormDataType>(user, form, formLabel);

  const submitButton = (label = "Continue") => (
    <Button
      type="submit"
      fakeDisabled={!form.formState.isValid}
      loading={form.formState.isSubmitting}
    >
      {label}
    </Button>
  );

  return { form, getInput, submitButton };
}

export function getError<T = unknown>(form: UseFormMethods<T>, name) {
  if (isTouched(form, name) || isSubmitted(form)) {
    return get(form.formState?.errors, name);
  }
}

function isTouched<T = unknown>(form: UseFormMethods<T>, name) {
  return get(form.formState?.touched, name, false);
}

function isSubmitted<T = unknown>(form: UseFormMethods<T>) {
  return form.formState?.isSubmitted;
}

export type FormType =
  | "signup"
  | "login"
  | "create basiq user"
  | "update user password"
  | "about you"
  | "personality type"
  | "income"
  | "add income"
  | "edit everyday"
  | "add everyday"
  | "edit fun"
  | "add fun"
  | "edit bill"
  | "add bill"
  | "edit holiday"
  | "add holiday"
  | "add goal"
  | "edit goal"
  | "edit goal account"
  | "edit investment property"
  | "mortgage set up"
  | "Projected Saving Setup"
  | "mortgage split set up"
  | "plannedPurchase"
  | "useableEquity"
  | "broker"
  | "forgotpassword"
  | "resetpassword"
  | "otp";

function createGetInput<T>(
  user: MeUserFragment,
  form: UseFormMethods<T>,
  formLabel: FormType
): GetInput<T> {
  const _text = createText(form);
  const _select = createSelect(form);
  const _selectWithoutLabels = createSelectWithoutLabels(form);
  const _currency = createCurrencyInput(form);
  const _currencyWithoutLabel = createCurrencyInputWithoutLabel(form);
  const _mobilenumber = createMobileNumberInput(form);
  const _slider = createSlider(form);
  const _sliderWithoutLabels = createSliderWithoutLabels(form);

  return function CustomInput(_fullName: FormKey<T>, extra?: any): ReactNode {
    const fullName: string = _fullName as string; // shut the type system up
    function text(options: TextOptions) {
      return _text(options, extra);
    }

    function select<OptionValue>(options: SelectOptions<OptionValue>) {
      return _select<OptionValue>(options, extra);
    }
    function selectWithoutLabels<OptionValue>(
      options: SelectOptions<OptionValue>
    ) {
      return _selectWithoutLabels<OptionValue>(options, extra);
    }

    // todo: this type is whack
    function currency<TControl = any>(
      options: Omit<CurrencyInputOptions<TControl>, "control">
    ) {
      return _currency(options, extra);
    }
    function currencyWithoutLabel<TControl = any>(
      options: Omit<CurrencyInputOptions<TControl>, "control">
    ) {
      return _currencyWithoutLabel(options, extra);
    }

    function mobilenumber<TControl = any>(
      options: Omit<CurrencyInputOptions<TControl>, "control">
    ) {
      return _mobilenumber(options, extra);
    }

    function slider(options: Omit<SliderInputOptions, "control">) {
      return _slider(options, extra);
    }
    function sliderWithoutLabels(options: Omit<SliderInputOptions, "control">) {
      return _sliderWithoutLabels(options, extra);
    }

    const name = last((fullName as string).split("."));

    switch (name) {
      case "name": {
        return text({ name: fullName, label: "Name" });
      }

      case "mobile": {
        return mobilenumber({
          name: fullName,
          label: "Mobile Number",
        });
      }
      case "relationshipStatus": {
        return select({
          name: fullName,
          options: relationshipStatusOptions,
          label: "This plan is for",
        });
      }
      case "partnerName": {
        const status = form.watch("relationshipStatus");
        return status === RelationshipStatus.Couple
          ? text({
              name: fullName,
              label: "Your Partner's Name",
              required: true,
            })
          : null;
      }
      case "dependents": {
        // TODO: setValueAs is probably the one here
        const setValue = (n) =>
          form.setValue("dependents" as any, ("" + n) as any);
        return (
          <SteppedNumberInput
            setValue={setValue}
            name={"dependents"}
            label={"Dependents:"}
            required
            inputRef={form.register({
              valueAsNumber: true,
            })}
            key={extra?.key || "dependents"}
          />
        );
      }
      case "livingSituation": {
        return select({
          name: fullName,
          options: livingSituationOptions,
          label: "Living Situation",
        });
      }
      case "basicRentAmount": {
        const showRentFields =
          form.watch("livingSituation") === LivingSituation.Renting;
        return !showRentFields
          ? null
          : currency({
              name: fullName,
              label: "Rent Amount",
            });
      }
      case "basicRentFrequency": {
        const showRentFields =
          form.watch("livingSituation") === LivingSituation.Renting;
        return !showRentFields
          ? null
          : select({
              name: fullName,
              label: "Frequency",
              options: billFrequencyOptions,
            });
      }
      case "rentalFrequency": {
        return select({
          name: fullName,
          label: "Rent Frequency",
          options: billFrequencyOptions,
        });
      }
      case "landTaxFrequency":
      case "otherFrequency":
      case "councilRatesFrequency":
      case "waterRatesFrequency":
      case "bodyFeesFrequency":
      case "landlordInsuranceFrequency":
      case "maintenanceFrequency":
      case "lettingFeesFrequency": {
        return select({
          name: fullName,
          label: "Frequency",
          options: billFrequencyOptions,
        });
      }
      case "landTaxAmount":
      case "otherAmount":
      case "councilRatesAmount":
      case "waterRatesAmount":
      case "bodyFeesAmount":
      case "landlordInsuranceAmount":
      case "maintenanceAmount":
      case "lettingFeesAmount": {
        return currency({ name: fullName, label: "Amount" });
      }

      case "landValue": {
        return currency({ name: fullName, label: "Land Value" });
      }
      case "propertyValue": {
        let showPropertyValue = true;

        if (extra["data-about-you"]) {
          showPropertyValue =
            form.watch("livingSituation") === LivingSituation.OwnHome;
        }
        if (!showPropertyValue) {
          return null;
        }
        return currency({ name: fullName, label: "Property Value" });
      }
      case "propertyType": {
        return select({
          name: fullName,
          label: "Property Type",
          options: PropertyTypeOptions,
        });
      }
      case "accountOffset": {
        const showFields =
          formLabel === "mortgage set up" ||
          formLabel === "mortgage split set up" ||
          form.watch("livingSituation") === LivingSituation.OwnHome;
        return !showFields
          ? null
          : currency({ name: fullName, label: "Account Offset" });
      }
      case "mortgageAmount": {
        const showMortgageFields =
          formLabel === "mortgage set up" ||
          formLabel === "mortgage split set up" ||
          form.watch("livingSituation") === LivingSituation.OwnHome;
        return !showMortgageFields
          ? null
          : currency({
              name: fullName,
              label: "Mortgage Amount",
              // formLabel === "mortgage set up"
              //   ? "Remaining Mortgage"
              //   : "Mortgage Amount",
            });
      }

      case "currentPassword": {
        return text({
          name: fullName,
          type: "password",
          label: "Current Password",
          autoComplete: "current-password",
          registerOptions: {
            required: required,
          },
        });
      }
      case "otp": {
        return text({
          name: fullName,
          type: "number",
          label: "OTP",
          registerOptions: {
            required: required,
            minLength: minLength(6),
            maxLength: maxLength(6),
          },
        });
      }
      case "password": {
        const isSignUpOrReset =
          formLabel === FORM_CONSTANT.SIGN_UP ||
          formLabel === FORM_CONSTANT.RESET_PASSWORD;
        return text({
          name: fullName,
          type: "password",
          label:
            formLabel === "update user password" ? "New Password" : "Password",
          autoComplete: isSignUpOrReset ? "new-password" : "current-password",
          explainText: isSignUpOrReset
            ? undefined
            : "Your password should contain at least 8 characters",
          registerOptions: {
            required: required,
            minLength: minLength(8),
            pattern:
              isSignUpOrReset &&
              pattern(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/
              ),
          },
        });
      }
      case "passwordRepeat": {
        return text({
          name: fullName,
          label: "Confirm Password",
          type: "password",
          autoComplete: "new-password",
          registerOptions: {
            required: required,
            validate: (value) =>
              value === form.watch("password") ||
              "The typed passwords do not match. Please try again.",
          },
        });
      }
      case "email": {
        return text({
          name: fullName,
          label: "Email",
          type: "email",
          autoComplete: "email",
          registerOptions: {
            required: required,
            minLength: minLength(3),
            setValueAs: (v) => v.toLowerCase(),
          },
        });
      }
      case "acceptsMarketing": {
        return (
          <CheckboxWithLabel
            name={name}
            id={name + "-input"}
            inputRef={form.register}
            label={
              "I want to be informed about new features! I agree to receive occasional communications from Golden Eggs"
            }
            {...extra}
          />
        );
      }
      case "personalityType1": {
        return select({
          name: fullName,
          options: personalityTypeOptions,
          label: "Person1",
        });
      }
      case "personalityType2": {
        return select({
          name: fullName,
          options: personalityTypeOptions,
          label: "Person2",
        });
      }
      case "amount": {
        // const label = formLabel.includes("income") ? "Net Amount" : "Amount";
        const suffix = formLabel.includes("everyday") ? " / week" : undefined;
        const required = ["add everyday"].includes(formLabel);

        return currency({
          name: fullName,
          required,
          label: "Amount",
          suffix,
        });
      }
      case "grossRentalAmount": {
        return currency({
          name: fullName,
          required: true,
          label: "Rent Amount",
        });
      }
      case "vacancyRateAmount": {
        return slider({
          name: fullName,
          label: "Vacancy Rate",
          min: 0,
          max: 10,
          suffix: "%",
        });
      }
      case "agentCommissionAmount": {
        return slider({
          name: fullName,
          label: "Agent Commission Amount (percentage of rent)",
          min: 0,
          max: 25,
          suffix: "%",
        });
      }

      case "targetLvr": {
        return slider({
          name: fullName,
          label: "Target LVR",
          min: 1,
          max: 90,
          step: 1,
          suffix: "%",
        });
      }
      case "mortgageInterest":
      case "mortgageInterestRate":
      case "interestRate":
      case "interestRate2": {
        let showInterestRateFields = true;
        if (extra["data-about-you"]) {
          showInterestRateFields =
            form.watch("livingSituation") === LivingSituation.OwnHome &&
            parseFloat(form.watch("mortgageAmount") as string) > 0;
        }
        if (!showInterestRateFields) {
          return;
        }
        return slider({
          name: fullName,
          label: "Interest Rate",
          min: 0,
          max: 10,
          step: 0.01,
          suffix: "%",
        });
      }
      case "goalAmount": {
        return currency({
          name: fullName,
          label: "Amount Needed",
        });
      }
      case "fnfAmount": {
        return currency({
          name: fullName,
          label:
            "Lifetime value of new Fixtures and Fittings (brand new properties only)",
        });
      }
      case "buildingAmount": {
        let amount = form.watch(fullName)
          ? formatCurrency((getInt(form.watch(fullName) as any) ?? 0) * 0.025)
          : "$";
        return currency({
          name: fullName,
          label:
            "Total Building costs (estimate if property built after 1987, otherwise $0 for older properties)",
          suffix: "= " + amount + " annual cost",
        });
      }
      case "mortgagePrinciple": {
        return currency({
          name: fullName,
          label: "Remaining Balance",
        });
      }
      case "goalYears": {
        return text({
          name: fullName,
          type: "number",
          label: "When needed",
          suffix: "/ years",
        });
      }
      case "incomeType": {
        return select({
          name: fullName,
          options: incomeTypeOptions,
          label: "Income Type",
          required: false,
        });
      }

      case "frequency": {
        if (formLabel.includes("holiday")) {
          return text({
            name: fullName,
            label: "Need in",
            type: "number",
            suffix: " years",
          });
        }
        return select({
          name: fullName,
          options: billFrequencyOptions,
          label: "Pay Frequency",
          required: false,
        });
      }
      case "hecsHelp": {
        return select({
          name: fullName,
          options: hecsHelpOptions,
          label: "HECS/HELP",
          required: false,
        });
      }

      case "label": {
        let label = "Expense Name";
        let required = false;
        if (formLabel === "edit goal") {
          return <B1>{(form.getValues() as any).label}</B1>;
        }

        if (formLabel.includes("income")) {
          label = "Income Name";
          required = true;
        } else if (formLabel.includes("goal")) {
          label = "Goal Name";
        }
        return text({ name: fullName, label, required: required });
      }
      case "key": {
        return select({
          name: fullName,
          options: goalKeyOptions,
          label: "Category",
        });
      }
      case "term": {
        return select({
          name: fullName,
          options: goalTermOptions,
          label: "Term",
        });
      }
      case "savingYear": {
        return slider({
          name: fullName,
          label: "How Many Years Do You Want To Project Your Saving?",
          min: 1,
          max: 30,
          step: 1,
          suffix: "years",
        });
      }
      case "loanRemainingYears": {
        return slider({
          name: fullName,
          label: "Loan Remaining Years",
          min: 1,
          max: 100,
          step: 1,
          suffix: "years",
        });
      }

      case "loanType":
      case "mortgage_type": {
        return select({
          name: fullName,
          options: MortageLoanTypes,
          label: "Select Your Mortage Type",
        });
      }

      case "state": {
        return select({
          name: fullName,
          options: StateOptions,
          label: "State",
        });
      }
      case "fbh": {
        return select({
          name: fullName,
          options: FbhOptions,
          label: "FHB",
        });
      }
      case "plannedPurchaseType": {
        return select({
          name: fullName,
          options: PlannedPurchaseOption,
          label: "Planned Purchase",
        });
      }
      case "lmiWaiver": {
        return select({
          name: fullName,
          options: getLmiWaiverOptionsWithChecks(
            extra["data-show-home-guarantee"]
          ),
          label: "LMI Waiver",
        });
      }
      case "keepVsSell": {
        return select({
          name: fullName,
          options: KeepVsSellOptions,
          label: "Keep Or Sell",
        });
      }

      case "purchaseLoanType": {
        return select({
          name: fullName,
          options: PurchaseLoanTypeOptions,
          label: "Loan Type",
        });
      }

      case "bankLoanType":
      case "bankLoanType2": {
        return select({
          name: fullName,
          options: MortageLoanTypes,
          label: "Select Loan Type",
        });
      }

      case "repaymentFrequency": {
        return select({
          name: fullName,
          options: RepaymentFrequencyOption,
          label: "Repayment Frequency",
        });
      }

      case "loanAmountPercentage": {
        return extra?.type === PurchaseLoanAmountType.Amount
          ? currency({ name: fullName, label: "Loan Amount" })
          : slider({
              name: fullName,
              label: "Loan Percentage",
              min: 1,
              max: 100,
              step: 1,
              suffix: "%",
            });
      }

      case "purchasePrice": {
        return currency({
          name: fullName,
          label: "Purchase Price",
        });
      }

      case "bankFees": {
        return currency({
          name: fullName,
          label: "Bank Fees",
        });
      }
      case "lenderLegals": {
        return currency({
          name: fullName,
          label: "Lender Legals",
        });
      }
      case "lenderFees": {
        return currency({
          name: fullName,
          label: "Legal Fees",
        });
      }
      case "refinanceCosts": {
        return currency({
          name: fullName,
          label: "Refinance Fees & Costs",
        });
      }

      case "otherFees": {
        return currency({
          name: fullName,
          label: "Other Fees (Building & Pest and Incidentals)",
        });
      }

      case "Sellingcosts": {
        return currency({
          name: fullName,
          label: "Selling Costs",
        });
      }

      case "savings": {
        return currency({ name: fullName, label: "Savings" });
      }
      case "gift": {
        return currency({ name: fullName, label: "Gift" });
      }

      case "shares": {
        return currency({ name: fullName, label: "Shares" });
      }
      case "equity": {
        return currency({ name: fullName, label: "Equity" });
      }

      case "personalLoan": {
        return currency({
          name: fullName,
          label: "Personal Loan",
        });
      }

      case "depositPaid": {
        return currency({
          name: fullName,
          label: "Deposit Paid",
        });
      }

      case "isCompanyTrust": {
        return (
          <ToggleWithLabel
            name={name}
            id={name + "-input"}
            inputRef={form.register}
            label={"Company/ Trust"}
            {...extra}
          />
        );
      }

      case "equityKeepVsSell": {
        return selectWithoutLabels({
          name: fullName,
          options: KeepVsSellOptions,
          label: "",
        });
      }

      case "useEquity": {
        return selectWithoutLabels({
          name: fullName,
          options: UseEquityOption,
          label: "",
        });
      }

      case "equityTargetLvr": {
        return sliderWithoutLabels({
          name: fullName,
          label: "",
          min: 1,
          max: 90,
          step: 1,
          suffix: "%",
        });
      }

      case "currentValue": {
        return currencyWithoutLabel({ name: fullName, label: "" });
      }

      case "brokerCalendlyLink": {
        return text({ name: fullName, label: "Calendly Link" });
      }

      case "brokerNumber": {
        return mobilenumber({
          name: fullName,
          label: "Mobile Number",
        });
      }

      case "defaultInterestRate": {
        return text({ name: fullName, label: "Interest Rate", type: "number" });
      }

      default: {
        throw new Error("unknown input type " + name);
      }
    }
  };
}

type TextOptions = InputProperties & {
  registerOptions?: RegisterOptions;
};

function createText<T>(form: UseFormMethods<T>) {
  function getProperties(options: TextOptions) {
    options.required = options.required ?? true;
    const registerOptions = options.registerOptions ?? {
      required: options.required ? required : undefined,
      minLength: minLength(1),
    };
    if (options.registerOptions) delete options.registerOptions;

    return {
      error: getError(form, options.name),
      inputRef: form.register({ ...registerOptions }),
      ...options,
    };
  }

  return function text(options: TextOptions, extra: any) {
    return <Input {...getProperties(options)} {...extra} />;
  };
}

export function formText<T>(
  form: UseFormMethods<T>,
  options: TextOptions,
  extra: any = {}
) {
  return createText(form)(options, extra);
}

type SliderInputOptions = Omit<SliderInputProps, "control">;

function createSlider<T>(form: UseFormMethods<T>) {
  function getProperties(options: SliderInputOptions) {
    return {
      error: getError(form, options.name),
      control: form.control,
      ...options,
    };
  }

  return function slider(options: SliderInputOptions, extra: any) {
    return (
      <SliderInput
        {...getProperties(options)}
        control={form.control}
        {...extra}
      />
    );
  };
}
function createSliderWithoutLabels<T>(form: UseFormMethods<T>) {
  function getProperties(options: SliderInputOptions) {
    return {
      error: getError(form, options.name),
      control: form.control,
      ...options,
    };
  }

  return function sliderWithoutLabels(options: SliderInputOptions, extra: any) {
    return (
      <SliderInputWithoutLabels
        {...getProperties(options)}
        control={form.control}
        {...extra}
      />
    );
  };
}

export function formSlider<T>(
  form: UseFormMethods<T>,
  options: SliderInputOptions,
  extra: any = {}
) {
  return createSlider(form)(options, extra);
}

type SelectOptions<T> = SelectProperties<T> & {
  registerOptions?: RegisterOptions;
};

function createSelect<F>(form: UseFormMethods<F>) {
  function getProperties<T>(options: SelectOptions<T>) {
    options.required = options.required ?? true;
    const registerOptions = options.registerOptions ?? {
      required: options.required ? required : undefined,
    };
    if (options.registerOptions) delete options.registerOptions;
    return {
      error: getError(form, options.name),
      inputRef: form.register(registerOptions),
      ...options,
    };
  }

  return function select<T>(options: SelectOptions<T>, extra: any) {
    return <Select {...getProperties(options)} {...extra} />;
  };
}
function createSelectWithoutLabels<F>(form: UseFormMethods<F>) {
  function getProperties<T>(options: SelectOptions<T>) {
    options.required = options.required ?? true;
    const registerOptions = options.registerOptions ?? {
      required: options.required ? required : undefined,
    };
    if (options.registerOptions) delete options.registerOptions;
    return {
      error: getError(form, options.name),
      inputRef: form.register(registerOptions),
      ...options,
    };
  }

  return function selectWithoutLabels<T>(
    options: SelectOptions<T>,
    extra: any
  ) {
    return <WithoutLabelSelect {...getProperties(options)} {...extra} />;
  };
}

type CurrencyInputOptions<T = any> = CurrencyInputProps<T> & {
  registerOptions?: RegisterOptions;
};

function createCurrencyInput<F>(form: UseFormMethods<F>) {
  function getProperties<T>(options: Omit<CurrencyInputOptions<T>, "control">) {
    options.required = options.required ?? true;
    const registerOptions = options.registerOptions ?? {
      required: options.required ? required : undefined,
    };
    if (options.registerOptions) delete options.registerOptions;

    return {
      error: getError(form, options.name),
      inputRef: form.register(registerOptions),
      ...options,
    };
  }

  return function currency<TControl extends Control<Record<string, any>>>(
    options: Omit<CurrencyInputOptions<TControl>, "control">,
    extra: Partial<CurrencyInputOptions<TControl>>
  ) {
    return (
      <CurrencyInput
        {...getProperties(options)}
        {...extra}
        control={form.control}
      />
    );
  };
}

function createCurrencyInputWithoutLabel<F>(form: UseFormMethods<F>) {
  function getProperties<T>(options: Omit<CurrencyInputOptions<T>, "control">) {
    options.required = options.required ?? true;
    const registerOptions = options.registerOptions ?? {
      required: options.required ? required : undefined,
    };
    if (options.registerOptions) delete options.registerOptions;

    return {
      error: getError(form, options.name),
      inputRef: form.register(registerOptions),
      ...options,
    };
  }

  return function currencyWithoutLabel<
    TControl extends Control<Record<string, any>>
  >(
    options: Omit<CurrencyInputOptions<TControl>, "control">,
    extra: Partial<CurrencyInputOptions<TControl>>
  ) {
    return (
      <CurrencyInputWithoutLabel
        {...getProperties(options)}
        {...extra}
        control={form.control}
      />
    );
  };
}

function createMobileNumberInput<F>(form: UseFormMethods<F>) {
  function getProperties<T>(options: Omit<CurrencyInputOptions<T>, "control">) {
    options.required = options.required ?? true;
    const registerOptions = options.registerOptions ?? {
      required: options.required ? required : undefined,
    };
    if (options.registerOptions) delete options.registerOptions;

    return {
      error: getError(form, options.name),
      inputRef: form.register(registerOptions),
      ...options,
    };
  }

  return function mobilenumber<TControl extends Control<Record<string, any>>>(
    options: Omit<CurrencyInputOptions<TControl>, "control">,
    extra: Partial<CurrencyInputOptions<TControl>>
  ) {
    return (
      <MobileNumberInput
        {...getProperties(options)}
        {...extra}
        control={form.control}
      />
    );
  };
}

export function formCurrency<F, TControl = any>(
  form: UseFormMethods<F>,
  options: Omit<CurrencyInputOptions<TControl>, "control">,
  extra: any = {}
) {
  return createCurrencyInput(form)(options, extra);
}

export function formSelect<F, T = any>(
  form: UseFormMethods<F>,
  options: SelectOptions<T>,
  extra: any = {}
) {
  return createSelect(form)(options, extra);
}
