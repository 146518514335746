import { Option } from "./Option";

import { LivingSituation } from "graphql/generated";

export const livingSituationOptions: Option<LivingSituation>[] = [
  {
    label: "Own Home",
    value: LivingSituation.OwnHome,
  },
  {
    label: "Renting",
    value: LivingSituation.Renting,
  },
  {
    label: "Living rent free", // This is for living with family
    value: LivingSituation.LivingWithFamily,
  },
];
